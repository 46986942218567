import { useEffect, useState, useMemo } from "react"
import queryString from "query-string"
import { useQuery } from "urql"
import { ProductsQuery, createQuery } from './search'

export function useProductSearch(
  filters,
  sortKey,
  count = 20
) {
  const [query, setQuery] = useState(createQuery(filters))
  const [cursors, setCursors] = useState({
    before: null,
    after: null,
  })
  const [initialRender, setInitialRender] = useState(true)
  const { term } = filters

  // Relevance is non-deterministic if there is no query, so we default to "title" instead
  const initialSortKey = filters.term ? "RELEVANCE" : "TITLE"

  const [result] = useQuery({
    query: ProductsQuery,
    variables: {
      query: query !== "" ? "NOT title:Piercing Appointment OR " + query : query,
      sortKey: sortKey || initialSortKey,
      first: !cursors.before ? count : null,
      last: cursors.before ? count : null,
      after: cursors.after,
      before: cursors.before,
    },
    pause: false,
  })

  useEffect(() => {
    const qs = queryString.stringify({
      // Don't show if falsy
      q: term || undefined,
      // Don't show if sort order is default
      s: sortKey === initialSortKey ? undefined : sortKey,
      c: cursors.after || undefined,
    })

    const url = new URL(window.location.href)
    url.search = qs
    url.hash = ""
    window.history.replaceState({}, null, url.toString())
    setQuery(createQuery(filters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, cursors, sortKey])

  const fetchPreviousPage = () => {
    // when we go back we want all products before the first one of our array
    const previousCursor = result.data.products.edges[0].cursor
    setCursors({
      before: previousCursor,
      after: null,
    })
  }
  const fetchNextPage = () => {
    // when we go forward we want all products after the first one of our array
    const prods = result.data.products
    const nextCursor = prods.edges[prods.edges.length - 1].cursor
    setCursors({
      before: null,
      after: nextCursor,
    })
  }

  let hasPreviousPage
  let hasNextPage

  const products = useMemo(() => {
    if (result.data && initialRender) setInitialRender(false)
    return result.data?.products?.edges || []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, result.data])

  if (result && result.data) {
    hasPreviousPage = result.data.products.pageInfo.hasPreviousPage
    hasNextPage = result.data.products.pageInfo.hasNextPage
  }

  const isFetching = !initialRender && result.fetching

  return {
    data: result.data,
    isFetching,
    hasPreviousPage,
    hasNextPage,
    products,
    fetchNextPage,
    fetchPreviousPage,
  }
}
