import * as React from "react"
import debounce from "debounce"
import { CgChevronRight, CgChevronLeft } from "react-icons/cg"
import CrossIcon from "../icons/cross"
import SortIcon from "../icons/sort"
import SearchIcon from "../icons/search"
import ProductCard from "../components/product-card"
import { useProductSearch } from "../utils/hooks"
import { getValuesFromQuery } from "../utils/search"
import { Spinner } from "../components/progress"
import { SearchProvider } from "../context/search-provider"
import { Layout } from "../components/layout/layout"
import {
  visuallyHidden,
  main,
  search,
  searchIcon,
  sortSelector,
  results,
  productListItem,
  progressStyle,
  clearSearch,
  searchForm,
  sortIcon,
  modalOpen,
  emptyState,
} from "./search-page.module.css"

const DEFAULT_PRODUCTS_PER_PAGE = 24

function SearchPage({
  location
}) {
  // These default values come from the page query string
  const queryParams = getValuesFromQuery(location.search)

  const [filters, setFilters] = React.useState(queryParams)
  const [sortKey, setSortKey] = React.useState(queryParams.sortKey)
  const [loading, setLoading] = React.useState(true)
  const [maxAspectRatio, setMaxAspectRatio] = React.useState(1)

  const {
    products,
    isFetching,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useProductSearch(
    filters,
    sortKey,
    DEFAULT_PRODUCTS_PER_PAGE
  )

  React.useEffect(() => {
    if (products.length > 0) {
      setLoading(false)

      products.forEach(({ node }) => {
        const imageWidth = node.featuredImage.width
        const imageHeight = node.featuredImage.height
        const aspectRatio = imageHeight / imageWidth
        if (aspectRatio > maxAspectRatio) setMaxAspectRatio(aspectRatio)
      })
    }
    else if (products.length === 0) {
      setLoading(false)
    }
  }, [products])

  return (
    <Layout>
      <div className="py-4 sm:px-4 md:px-8 mx-auto max-w-screen-xl">
        <h1 className={visuallyHidden}>Search Results</h1>
        <div className={main}>
          <div className={search} aria-hidden={modalOpen}>
            <SearchBar defaultTerm={filters.term} setFilters={setFilters} />
            <div className={sortSelector}>
              <label>
                <span>Sort by:</span>
                <select
                  value={sortKey}
                  // eslint-disable-next-line
                  onChange={(e) => setSortKey(e.target.value)}
                >
                  <option value="RELEVANCE">Relevance</option>
                  <option value="PRICE">Price</option>
                  <option value="TITLE">Title</option>
                  <option value="CREATED_AT">New items</option>
                  <option value="BEST_SELLING">Trending</option>
                </select>
              </label>
              <SortIcon className={sortIcon} />
            </div>
          </div>
          <section
            className={results}
            aria-busy={isFetching}
            aria-hidden={modalOpen}
          >
            <div className="flex items-center justify-center pb-4">
              {isFetching || loading ? (
                <p className={progressStyle}>
                  <Spinner aria-valuetext="Searching" /> Searching
                  {filters.term ? ` for "${filters.term}"…` : `…`}
                </p>
              ) : (
                <p className={`pt-4`}>
                  Search results{" "}
                  {filters.term && (
                    <>
                      for "<span>{filters.term}</span>"
                    </>
                  )}
                </p>
              )}
            </div>
            {!isFetching && (
              <ul className={`grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 sm:gap-3 px-1`}>
                {products.map(({ node }, index) => (
                  <li className={productListItem} key={node.id}>
                    <ProductCard
                      eager={index < 8}
                      product={{
                        title: node.title,
                        handle: node.handle,
                        priceRangeV2: node.priceRangeV2,
                        slug: `/${node.handle}`,
                        media: [],
                        storefrontImages: [node.featuredImage],
                        metafields: node.metafields.edges.map(edge => edge.node),
                      }}
                      maxAspectRatio={maxAspectRatio}
                      template="search"
                      viewMode={`list`}
                    />
                  </li>
                ))}
              </ul>
            )}
            {!isFetching && products.length === 0 && (
              <div className={emptyState}>No results found</div>
            )}
            {hasPreviousPage || hasNextPage ? (
              <Pagination
                previousPage={fetchPreviousPage}
                hasPreviousPage={hasPreviousPage}
                nextPage={fetchNextPage}
                hasNextPage={hasNextPage}
              />
            ) : undefined}
          </section>
        </div>
      </div>
    </Layout>
  )
}

function SearchBar({ defaultTerm, setFilters }) {
  const [term, setTerm] = React.useState(defaultTerm)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFilters = React.useCallback(
    debounce((value) => {
      setFilters((filters) => ({ ...filters, term: value }))
    }, 200),
    [setFilters]
  )

  return (
    <form onSubmit={(e) => e.preventDefault()} className={searchForm}>
      <SearchIcon aria-hidden className={searchIcon} />
      <input
        type="text"
        value={term}
        onChange={(e) => {
          setTerm(e.target.value)
          debouncedSetFilters(e.target.value)
        }}
        placeholder="Search..."
      />
      {term ? (
        <button
          className={clearSearch}
          type="reset"
          onClick={() => {
            setTerm("")
            setFilters((filters) => ({ ...filters, term: "" }))
          }}
          aria-label="Clear search query"
        >
          <CrossIcon />
        </button>
      ) : undefined}
    </form>
  )
}
/**
 * Shopify only supports next & previous navigation
 */
function Pagination({ previousPage, hasPreviousPage, nextPage, hasNextPage }) {
  return (
    <nav className={`flex items-center justify-between w-full py-4 mt-8 px-1`}>
      <button
        className={`border border-primary text-primary rounded-sm font-bold py-3 px-4 mr-2 flex items-center hover:bg-primary hover:text-white text-sm ${!hasPreviousPage && 'cursor-not-allowed opacity-50'}`}
        disabled={!hasPreviousPage}
        onClick={previousPage}
        aria-label="Previous page"
      >
        <CgChevronLeft />
        <span>Previous page</span>
      </button>
      <button
        className={`border border-primary bg-primary text-white rounded-sm font-bold py-3 px-4 ml-2 flex items-center text-sm ${!hasNextPage && 'cursor-not-allowed opacity-50'}`}
        disabled={!hasNextPage}
        onClick={nextPage}
        aria-label="Next page"
      >
        <span>Next page</span>
        <CgChevronRight />
      </button>
    </nav>
  )
}

export default function SearchPageTemplate(props) {
  return (
    <SearchProvider>
      <SearchPage {...props} />
    </SearchProvider>
  )
}
